import React, { useState, useContext } from "react";
import {
  AppBar,
  makeStyles,
  Toolbar,
  Typography,
  Button,
} from "@material-ui/core";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useEffect } from "react";
import { GlobalContext } from "../../globalContext";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: "#21517a",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  button: {
    color: "white",
  },
}));

const drawerWidth = 240;

export default function Header({ open, handleDrawerOpen, logout, props }) {
  const classes = useStyles();
  const [title, setTitle] = useState("Painel de Controle");
  const [global] = useContext(GlobalContext);

  useEffect(() => {
    const changeTitle = () => {
      switch (props.location.pathname) {
        case "/admin/dashboard":
          setTitle("Painel de Controle");
          break;
        case "/admin/pedidos":
          setTitle("Pedidos");
          break;
        case "/admin/produtos":
          setTitle("Produtos");
          break;
        case "/admin/transacoes":
          setTitle("Transações");
          break;
        case "/admin/usuarios":
          setTitle("Usuários");
          break;
        case "/admin/marcas":
          setTitle("Marcas");
          break;
        case "/admin/vitrine":
          setTitle("Vitrine");
          break;
        default:
          break;
      }
    };
    changeTitle();
  }, [props]);

  return (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar, open && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          {title}
        </Typography>
        <Button
          size="medium"
          className={classes.button}
          startIcon={<AccountCircle />}
        >
          Admin: {global.user.firstname}
        </Button>
        <Button
          size="medium"
          className={classes.button}
          startIcon={<ExitToAppIcon />}
          onClick={() => logout()}
        >
          Sair
        </Button>
      </Toolbar>
    </AppBar>
  );
}
