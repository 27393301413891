import React, { useEffect, useContext, useState } from "react";
import Container from "@material-ui/core/Container";
import Header from "./Header/Header";
import Footer from "./Footer";
import { animateScroll as scroll } from "react-scroll";
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext } from "../../services/Auth";
import { GlobalContext } from "../../globalContext";
import { CssBaseline, Backdrop, CircularProgress } from "@material-ui/core";
import app from "../../services/firebase";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import "../../styles/style.css";
import { ButtonGoToTop } from "./Buttons/ButtonElements";
import ScrollToTop from "../ScrollToTop";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "calc(100vh - 350px)",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    marginTop: 169,
    paddingTop: 20,
    paddingBottom: 10,
    "@media (max-width:960px)": {
      marginTop: 142,
    },
    "@media (min-width:961px)": {
      marginTop: 162,
    },
  },
  backStyle1: {
    [theme.breakpoints.up("sm")]: {
      background: "#fafafa",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function Template(props) {
  const classes = useStyles();
  const { currentUser } = useContext(AuthContext);
  const [global, setGlobal] = useContext(GlobalContext);
  const [categorias, setCategorias] = useState([]);
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 400) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  useEffect(() => {
    const verificaPedido = (uid) => {
      app
        .firestore()
        .collection("pedido")
        .where("userUid", "==", uid)
        .where("status", "==", 1)
        .get()
        .then(function (doc) {
          doc.forEach(function (doc) {
            let obj = doc.data();
            Object.assign(obj, { id: doc.id });
            setGlobal({
              type: "setPedido",
              payload: obj,
            });
          });
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    };
    if (currentUser || global.verificaUser) {
      app
        .firestore()
        .collection("users")
        .doc(currentUser.uid)
        .get()
        .then(function (doc) {
          if (doc.exists) {
            setGlobal({
              type: "setUser",
              payload: doc.data(),
            });
            verificaPedido(doc.data().userUid);
          }
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    }
  }, [currentUser, setGlobal, global.verificaUser]);

  useEffect(() => {
    const verificaPedido = (uid) => {
      app
        .firestore()
        .collection("pedido")
        .where("userUid", "==", uid)
        .where("status", "==", 1)
        .get()
        .then(function (doc) {
          doc.forEach(function (doc) {
            let obj = doc.data();
            Object.assign(obj, { id: doc.id });
            setGlobal({
              type: "setPedido",
              payload: obj,
            });
          });
          setGlobal({
            type: "setVerificaPedido",
            payload: false,
          });
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    };
    if (global.verificaPedido) {
      verificaPedido(global.user.userUid);
    }
  }, [global.verificaPedido, global.user.userUid, setGlobal]);

  useEffect(() => {
    const getCategorias = async () => {
      return await app
        .firestore()
        .collection("categorias")
        .where("ativo", "==", true)
        .orderBy("createdAt", "asc")
        .get()
        .then(function (doc) {
          let aux = [];
          doc.forEach(function (doc) {
            let obj = doc.data();
            Object.assign(obj, { idFirestore: doc.id });
            aux.push(obj);
          });
          setCategorias(aux);
          return 1;
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
          return 2;
        });
    };
    getCategorias();
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <ScrollToTop />
      <CssBaseline />
      <Backdrop className={classes.backdrop} open={global.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Header id="styledHeader" currentUser={currentUser} categorias={categorias} props={props} />
      <main id="main-background">
        <Container
          categorias={categorias}
          component="main"
          maxWidth="lg"
          className={classes.container}
        >
          {props.children}
        </Container>
      </main>
      <Footer categorias={categorias} />
      <ButtonGoToTop
        title="Ir ao topo"
        to="styledHeader"
        smooth={true}
        duration={400}
        spy={true}
        onClick={toggleHome}
        scrollnav={scrollNav.toString()}
      >
        <ArrowUpwardIcon />
      </ButtonGoToTop>
    </>
  );
}
