import styled from "styled-components";
import { Link } from "react-scroll";

export const ButtonGoToTop = styled(Link)`
  opacity: ${({ scrollnav }) => (scrollnav === "true" ? "100%" : "0%")};
  position: fixed;
  bottom: 10px;
  right: 10px;
  border-radius: 50px;
  background: #00572c;
  white-space: nowrap;
  padding: 8px 13px;
  color: #fff;
  font-size: 12px;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
`;
