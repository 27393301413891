import React from 'react';
import ReactDOM from 'react-dom';
// import * as serviceWorker from './serviceWorker';
import Routes from "./Routes";
import { Router } from "react-router-dom";
import history from './services/history';
import { GlobalContextProvider } from './globalContext';
import { AuthProvider } from "./services/Auth";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';

toast.configure({
    autoClose: 5000,
    position: 'bottom-right',
    draggable: true,
});

const theme = createMuiTheme({
    typography: {
        overline: {
            fontSize: '0.6rem',
            '@media (min-width:600px)': {
              fontSize: '0.8rem',
            },
        }, 
        h1: {
            fontFamily: "Handlee, cursive",
        },
        h2: {
            fontFamily: "Handlee, cursive",
        },
        h3: {
            fontFamily: "Handlee, cursive",
        },
        h4: {
            fontFamily: "Handlee, cursive",
        },
        h5: {
            fontFamily: "Handlee, cursive",
            '@media (min-width:600px)': {
              fontSize: '1.5rem',
            },
        },
        h6: {
            fontFamily: "Handlee, cursive",
        },
    },
    overrides: {
        MuiButton: {
            text: {
                fontWeight: 'bold',
                fontFamily: "Handlee, cursive",
            },
        },
    }
}, ptBR);

ReactDOM.render(
    <AuthProvider>
        <GlobalContextProvider>
            <ThemeProvider theme={theme}>
                <Router history={history}>
                    <Routes props={history} />
                </Router>
            </ThemeProvider>
        </GlobalContextProvider>
    </AuthProvider>,
    document.getElementById('root')
);

// serviceWorker.unregister();
