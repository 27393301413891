import React, { createContext, useReducer } from 'react'

const initialState = {
    user: {
        displayName: null,
        emailAuth: null,
        phoneNumber: null,
        photoURL: null,
        uid: null,
        emailVerified: null,
        acceptMsg: false,
        ativo: false,
        idade: "",
        cpf: "",
        email: "",
        firstname: "",
        lastname: "",
        cidade: "",
        uf: "",
        userType: 0,
        pedido: null,
        question: null
    },
    pedido: {
        createdAt: null,
        id: null,
        updatedAt: null,
        userUid: null,
        status: null,
        itens: null
    },
    verificaPedido: false,
    isCheckingAuth: true,
    verificaUser: false,
    loading: false
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'reset':
            return initialState
        case 'setUser':
            return { ...state, user: action.payload, isCheckingAuth: false }
        case 'setPedido':
            return { ...state, pedido: action.payload }
        case 'setVerificaPedido':
            return { ...state, verificaPedido: action.payload }
        case 'setVerificaUser':
            return { ...state, verificaUser: action.payload }
        case 'isCheckingAuth':
            return { ...state, isCheckingAuth: action.payload }
        case 'setLoading':
            return { ...state, loading: action.payload }
        default:
            return state
    }
}

const GlobalContext = createContext(initialState)
const GlobalContextProvider = props => <GlobalContext.Provider value={useReducer(reducer, initialState)}>{props.children}</GlobalContext.Provider>

export { GlobalContext, GlobalContextProvider }
