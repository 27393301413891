import React from "react";
import {
  Badge,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  useTheme,
  withStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import history from "../../../services/history";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import HomeIcon from "@material-ui/icons/Home";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import AppsIcon from "@material-ui/icons/Apps";
import PersonIcon from "@material-ui/icons/Person";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

const drawerWidth = 240;

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge);

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
      width: drawerWidth,
    },
    closeMenuButton: {
      marginRight: 0,
      marginLeft: "auto",
    },
  }));

export default function DrawerMenu({
  props,
  handleDrawerToggle,
  mobileOpen,
  setMobileOpen,
  currentUser,
  handleMenuItemClick,
  global
}) {
  const theme = useTheme();
  const classes = useStyles();

  const onClickListDrawer = (path) => {
    history.push(path);
    setMobileOpen(false);
  };

  const drawer = (
    <div>
      {!currentUser ? (
        <List>
          <ListItem button onClick={() => onClickListDrawer("/login")}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={"Entrar"} />
          </ListItem>
          <Divider />
          {props.location.pathname !== "/" ? (
            <ListItem button onClick={() => onClickListDrawer("/")}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary={"Início"} />
            </ListItem>
          ) : null}
          <ListItem button onClick={() => onClickListDrawer("/quem-somos")}>
            <ListItemIcon>
              <LocalLibraryIcon />
            </ListItemIcon>
            <ListItemText primary={"Quem somos"} />
          </ListItem>
        </List>
      ) : (
        <List>
          <ListItem>
            <ListItemText
              primary={`Olá, ${global.user.firstname} ${global.user.lastname}`}
              secondary={global.user.email}
            />
          </ListItem>
          <Divider />
          {props.location.pathname !== "/" ? (
            <ListItem button onClick={() => onClickListDrawer("/")}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary={"Início"} />
            </ListItem>
          ) : null}
          <ListItem button onClick={() => handleMenuItemClick(0)}>
            <ListItemIcon>
              <AppsIcon />
            </ListItemIcon>
            <ListItemText primary={"Minha Conta"} />
          </ListItem>
          <ListItem button onClick={() => handleMenuItemClick(4)}>
            <ListItemIcon>
              <StyledBadge badgeContent={global.pedido.itens} color="error">
                <ShoppingCartIcon fontSize="large" style={{ fontSize: 24 }} />
              </StyledBadge>
            </ListItemIcon>
            <ListItemText primary={"Carrinho"} />
          </ListItem>
          <ListItem button onClick={() => handleMenuItemClick(1)}>
            <ListItemIcon>
              <FormatListNumberedIcon />
            </ListItemIcon>
            <ListItemText primary={"Meus Pedidos"} />
          </ListItem>
          <ListItem button onClick={() => handleMenuItemClick(2)}>
            <ListItemIcon>
              <AssignmentIndIcon />
            </ListItemIcon>
            <ListItemText primary={"Meus Dados"} />
          </ListItem>
          <ListItem button onClick={() => onClickListDrawer("/quem-somos")}>
            <ListItemIcon>
              <LocalLibraryIcon />
            </ListItemIcon>
            <ListItemText primary={"Quem somos"} />
          </ListItem>
          <Divider />
          <ListItem button onClick={() => handleMenuItemClick(3)}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={"Sair"} />
          </ListItem>
        </List>
      )}
    </div>
  );

  return (
    <Drawer
      variant="temporary"
      anchor={theme.direction === "rtl" ? "right" : "left"}
      open={mobileOpen}
      onClose={handleDrawerToggle}
      classes={{
        paper: classes.drawerPaper,
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <IconButton onClick={handleDrawerToggle} className={classes.closeMenuButton}>
        <CloseIcon />
      </IconButton>
      {drawer}
    </Drawer>
  );
}
