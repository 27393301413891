import React from "react";
import {
  Drawer,
  Divider,
  List,
  makeStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PeopleIcon from "@material-ui/icons/People";
import LayersIcon from "@material-ui/icons/Layers";
import history from "../../services/history";
import StoreIcon from "@material-ui/icons/Store";
import PermMediaIcon from '@material-ui/icons/PermMedia';

const useStyles = makeStyles((theme) => ({
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

const drawerWidth = 240;

export default function Sidebar({ open, handleDrawerClose }) {
  const classes = useStyles();
  const changeTitle = (nome) => {
    history.push(`/admin/${nome}`);
  };

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      {/* LISTA SUPERIOR */}
      <List>
        <ListItem button onClick={() => changeTitle("dashboard")}>
          <ListItemIcon title={"Painel de Controle"}>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Painel de Controle" />
        </ListItem>
        <ListItem button onClick={() => changeTitle("pedidos")}>
          <ListItemIcon title={"Pedidos"}>
            <ShoppingCartIcon />
          </ListItemIcon>
          <ListItemText primary="Pedidos" />
        </ListItem>
        <ListItem button onClick={() => changeTitle("produtos")}>
          <ListItemIcon title={"Produtos"}>
            <StoreIcon />
          </ListItemIcon>
          <ListItemText primary="Produtos" />
        </ListItem>
        <ListItem button onClick={() => changeTitle("marcas")}>
          <ListItemIcon title={"Marcas"}>
            <StoreIcon />
          </ListItemIcon>
          <ListItemText primary="Marcas" />
        </ListItem>
        <ListItem button onClick={() => changeTitle("transacoes")}>
          <ListItemIcon title={"Transações"}>
            <LayersIcon />
          </ListItemIcon>
          <ListItemText primary="Transações" />
        </ListItem>
        <ListItem button onClick={() => changeTitle("usuarios")}>
          <ListItemIcon title={"Usuários"}>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Usuários" />
        </ListItem>
        <ListItem button onClick={() => changeTitle("midias")}>
          <ListItemIcon title={"Mídias"}>
            <PermMediaIcon />
          </ListItemIcon>
          <ListItemText primary="Mídias" />
        </ListItem>
        <ListItem button onClick={() => changeTitle("vitrine")}>
          <ListItemIcon title={"Vitrine"}>
            <StoreIcon />
          </ListItemIcon>
          <ListItemText primary="Vitrine" />
        </ListItem>
      </List>
      <Divider />
    </Drawer>
  );
}
