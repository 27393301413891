import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  IconButton,
  Container,
  Divider,
} from "@material-ui/core";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import history from "../../services/history";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import backgroundImage from "../../assets/background-grey.png";
import packageJson from "../../../package.json";

const useStyles = makeStyles((theme) => ({
  footer: {
    background: `url(${backgroundImage})`,
    minHeight: "230px",
    padding: 20,
  },
  itens: {
    fontSize: 15,
    color: "#f7f7f7",
  },
  title: {
    fontSize: 16,
    fontFamily: "Handlee, cursive",
    fontWeight: 900,
    color: "#f7f7f7",
    marginBottom: 10,
  },
  itensClick: {
    fontFamily: "Handlee, cursive",
    fontWeight: 900,
    color: "#f7f7f7",
    "&:hover": {
      color: "#33b054",
      cursor: "pointer",
    },
  },
  itensV: {
    fontSize: 10,
    color: "#f7f7f7",
  },
}));

// const categorias = [
//   { categoria: "Anéis, Brincos e Pulseiras", path: "1" },
//   { categoria: "Bolsas", path: "2" },
//   { categoria: "Casa", path: "3" },
//   { categoria: "Colares", path: "4" },
//   { categoria: "Conservas", path: "5" },
//   { categoria: "Fibra", path: "6" },
//   { categoria: "Infantil", path: "6" },
//   { categoria: "Madeira", path: "6" },
//   { categoria: "Roupas", path: "6" },
//   { categoria: "Tecidos", path: "6" },
// ];

export default function Footer() {
  const classes = useStyles();

  const [social] = useState([
    { name: "(35) 99950-6232", icon: WhatsAppIcon },
    { name: "vivaminasartesanal@gmail.com", icon: MailOutlineIcon },
  ]);

  return (
    <footer id="footer" className={classes.footer}>
      <Container component="main" maxWidth="lg">
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          align={"center"}
        >
          {/* LINHA 1 */}
          {/* <Grid item xs={6} sm={6} md={4} style={{ marginBottom: 10 }}>
            {categorias.length &&
              categorias.map((item, index) => (
                <Typography
                  key={index}
                  color="textSecondary"
                  className={classes.itensClick}
                  onClick={() =>
                    history.push(`/produtos/categoria?id=${item.id}`)
                  }
                >
                  {item.categoria}
                </Typography>
              ))}
          </Grid> */}
          <Grid item xs={6} sm={6} md={6}>
            <Typography className={classes.title}>Redes Sociais</Typography>
            <IconButton
              onClick={() =>
                window.open(
                  "https://www.instagram.com/viva.minas/",
                  "Instagram"
                )
              }
              component="span"
              title="Instagram"
              style={{ marginRight: 20 }}
            >
              <InstagramIcon style={{ color: "#f7f7f7" }} fontSize="large" />
            </IconButton>
            <IconButton
              onClick={() =>
                window.open("https://www.facebook.com/viva.minas", "Facebook")
              }
              component="span"
              title="Facebook"
            >
              <FacebookIcon style={{ color: "#f7f7f7" }} fontSize="large" />
            </IconButton>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography color="textPrimary" className={classes.title}>
              Contatos
            </Typography>
            {social.map((network, index) => (
              <Typography
                key={index}
                color="textSecondary"
                className={classes.itens}
              >
                {network.name}
              </Typography>
            ))}
          </Grid>
          {/* LINHA 2 */}
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} align="center">
              <Typography
                className={classes.itensClick}
                variant="overline"
                gutterBottom
              >
                Compre fazendo contato pelo whatsapp ou email e aproveite para tirar suas dúvidas!!
              </Typography>
            </Grid>
            {/* <Grid item xs={12} md={8} align="center">
              <div style={{ overflow: "auto" }}>
                <img
                  src="https://assets.pagseguro.com.br/ps-integration-assets/banners/pagamento/todos_estatico_550_100.gif"
                  alt="Logotipos de meios de pagamento do PagSeguro"
                  title="Este site aceita pagamentos com as principais bandeiras e bancos, saldo em conta PagSeguro e boleto."
                />
              </div>
            </Grid>
            <Grid item xs={12} md={4} align="center">
              <div style={{ overflow: "auto" }}>
                <img
                  src="https://assets.pagseguro.com.br/ps-integration-assets/banners/seguranca/seguranca_125x125.gif"
                  alt="Banner PagSeguro"
                  title="Compre com PagSeguro e fique sossegado"
                />
              </div>
            </Grid> */}
            {/* LINHA 3 */}
            <Grid item xs={12} md={12} align="center">
              <Divider />
            </Grid>
            <Grid item xs={12} md={6} align="center">
              <Typography
                onClick={() => history.push("/politica")}
                className={classes.itensClick}
                variant="overline"
                gutterBottom
              >
                Políticas de vendas
              </Typography>
              <Typography className={classes.itens}>
                © 2021 . Todos os direitos reservados. Conteúdo licenciado.
              </Typography>
              <Typography className={classes.itens}>
                www.vivaminasartesanal.com.br
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} align="center">
              <Typography className={classes.itens}>
                Rua Vereador Urias José de Andrade, 141 - Bairro Centro, Estiva
                - Minas Gerais – CEP 37.542-000
              </Typography>
              <Typography className={classes.itens}>
                CNPJ: 37.187.003/001-96
              </Typography>
            </Grid>
            {/* LINHA 4 */}
            <Grid item xs={12} md={12} align="center">
              <Divider />
            </Grid>
            <Grid item xs={12} md={12} align="center">
              <Typography className={classes.itensV}>
                Versão {packageJson.version}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}
