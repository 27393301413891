import React, { Suspense } from "react";
import { Route, Redirect } from "react-router-dom";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import TemplateAdmin from "./components/Adm/Template";
import SwitchRouter from "./components/Loja/SwitchRouter";
// import TemplateManutencao from "./components/Manutencao/TemplateManutencao";
import Template from "./components/Loja/Template";

//MAIN SITE
const Home = React.lazy(() => import("./screens/Loja/Home"));
const Produto = React.lazy(() => import("./screens/Loja/Produtos/ProdutoDetails"));
const Login = React.lazy(() => import("./screens/Loja/Login"));
const Perfil = React.lazy(() => import("./screens/Loja/User/Perfil"));
const Carrinho = React.lazy(() => import("./screens/Loja/Carrinho/Carrinho"));
const MinhaConta = React.lazy(() => import("./screens/Loja/MinhaConta"));
const RedefinirSenha = React.lazy(() => import("./screens/Loja/RedefinirSenha"));
const Redirecionar = React.lazy(() => import("./screens/Loja/Carrinho/Redirecionar"));
const Marcas = React.lazy(() => import("./screens/Loja/Marcas/Marcas"));
const InicioProdutosMarca = React.lazy(() => import("./screens/Loja/Produtos/PorMarca/Inicio"));
const InicioProdutosCategorias = React.lazy(() =>
  import("./screens/Loja/Produtos/PorCategoria/Inicio")
);
const Cancelamento = React.lazy(() => import("./screens/Loja/User/CancelarPedido"));
const QuemSomos = React.lazy(() => import("./screens/Loja/QuemSomos"));
const Politica = React.lazy(() => import("./screens/Loja/Politica"));
const PosPagamento = React.lazy(() => import("./screens/Loja/PosPagamento"));
const Vitrine = React.lazy(() => import("./screens/Loja/Vitrine/Vitrine"));
// const RostoSite = React.lazy(() => import("./screens/Loja/vivaminas/RostoSite"));

//ADMIN SITE
const LoginAdm = React.lazy(() => import("./screens/Administrador/Login"));
const Dashboard = React.lazy(() => import("./screens/Administrador/Dashboard"));
const Usuarios = React.lazy(() => import("./screens/Administrador/Usuarios/Usuarios"));
const Pedidos = React.lazy(() => import("./screens/Administrador/Pedidos"));
const Produtos = React.lazy(() => import("./screens/Administrador/Produtos/Produtos"));
const Transacoes = React.lazy(() => import("./screens/Administrador/Transacoes"));
const NotFoundAdmin = React.lazy(() => import("./screens/Administrador/NotFoundAdmin"));
const MarcasAdm = React.lazy(() => import("./screens/Administrador/Marcas/Marcas"));
const MidiasAdm = React.lazy(() => import("./screens/Administrador/Midias/Midias"));
const ProdutosNovidade = React.lazy(() => import("./screens/Administrador/ProdutosNovidade"));
const VitrineAdm = React.lazy(() => import("./screens/Administrador/Vitrine/Vitrine"));

// MANUTENÇÃO
// const HomeManutencao = React.lazy(() => import("./screens/Manutencao/Home"));
const NotFound = React.lazy(() => import("./screens/NotFound"));

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function Routes() {
  const classes = useStyles();

  return (
    <Route
      render={(props) => {
        if (!/admin/i.test(props.location.pathname)) {
          return (
            <Template {...props}>
              <Suspense
                fallback={
                  <Backdrop className={classes.backdrop} open={true}>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                }
              >
                <SwitchRouter>
                  <Route exact path="/" component={Home} />
                  <Route path="/produtos/marca" component={InicioProdutosMarca} />
                  <Route path="/produtos/categoria" component={InicioProdutosCategorias} />
                  <Route path="/produto" component={Produto} />
                  <Route path="/login" component={Login} />
                  <Route path="/perfil" component={Perfil} />
                  <Route path="/carrinho" component={Carrinho} />
                  <Route path="/minha-conta" component={MinhaConta} />
                  <Route path="/minha-conta/pedidos" component={MinhaConta} />
                  <Route path="/minha-conta/cancelamento" component={Cancelamento} />
                  <Route path="/minha-conta/perfil" component={MinhaConta} />
                  <Route path="/minha-conta/enderecos" component={MinhaConta} />
                  <Route path="/redefinir" component={RedefinirSenha} />
                  <Route path="/quem-somos" component={QuemSomos} />
                  <Route path="/politica" component={Politica} />
                  <Route path="/redirecionar" component={Redirecionar} />
                  <Route path="/marcas" component={Marcas} />
                  <Route path="/pos-pagamento" component={PosPagamento} />
                  <Route path="/vitrine" component={Vitrine} />
                  <Route path="/404" component={NotFound} />
                  <Redirect from="*" to="/404" />
                </SwitchRouter>
              </Suspense>
            </Template>
          );
        } else {
          return (
            <TemplateAdmin {...props}>
              <Suspense
                fallback={
                  <Backdrop className={classes.backdrop} open={true}>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                }
              >
                <SwitchRouter>
                  <Route exact path="/admin" component={LoginAdm} />
                  <Route exact path="/admin/dashboard" component={Dashboard} />
                  <Route exact path="/admin/usuarios" component={Usuarios} />
                  <Route exact path="/admin/pedidos" component={Pedidos} />
                  <Route exact path="/admin/produtos" component={Produtos} />
                  <Route exact path="/admin/transacoes" component={Transacoes} />
                  <Route exact path="/admin/marcas" component={MarcasAdm} />
                  <Route exact path="/admin/midias" component={MidiasAdm} />
                  <Route exact path="/admin/novidades" component={ProdutosNovidade} />
                  <Route exact path="/admin/vitrine" component={VitrineAdm} />
                  <Route path="/admin/404" component={NotFoundAdmin} />
                  <Redirect from="/admin/*" to="/admin/404" />
                </SwitchRouter>
              </Suspense>
            </TemplateAdmin>
          );
        }
      }}
    />
  );
}
