import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext } from "../../services/Auth";
import app from "../../services/firebase";
import { GlobalContext } from "../../globalContext";
import history from "../../services/history";
import Header from "./Header";
import { Backdrop, CircularProgress, CssBaseline } from "@material-ui/core";
import Sidebar from "./Sidebar";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function TemplateAdmin(props) {
  const classes = useStyles();
  const { currentUser } = useContext(AuthContext);
  const [global, setGlobal] = useContext(GlobalContext);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    app.auth().onAuthStateChanged((user) => {
      if (!user) {
        history.push("/admin");
      }
    });
  }, []);

  useEffect(() => {
    if (currentUser) {
      app
        .firestore()
        .collection("users")
        .doc(currentUser.uid)
        .get()
        .then(function (doc) {
          if (doc.exists) {
            setGlobal({
              type: "setUser",
              payload: doc.data(),
            });
          }
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    }
  }, [currentUser, setGlobal]);

  const logout = () => {
    app
      .auth()
      .signOut()
      .then(function () {
        setGlobal({
          type: "reset",
        });
        history.push("/admin");
        toast.success("Deslogado com sucesso!");
      })
      .catch(function (error) {
        console.log("Erro");
      });
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {props.location.pathname === "/admin" ? (
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          {props.children}
        </main>
      ) : (
        <div className={classes.root}>
          <CssBaseline />
          <Backdrop className={classes.backdrop} open={global.loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Header
            open={open}
            setOpen={setOpen}
            handleDrawerOpen={handleDrawerOpen}
            handleDrawerClose={handleDrawerClose}
            logout={logout}
            props={props}
          />
          <Sidebar open={open} handleDrawerClose={handleDrawerClose} />
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            {props.children}
          </main>
        </div>
      )}
    </React.Fragment>
  );
}
