import React, { useState, useRef, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Toolbar,
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemText,
  Container,
  Hidden,
  IconButton,
  AppBar,
} from "@material-ui/core";
// import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { GlobalContext } from "../../../globalContext";
import history from "../../../services/history";
// import Badge from "@material-ui/core/Badge";
import { AuthContext } from "../../../services/Auth";
import app from "../../../services/firebase";
import logoVivaMinas from "../../../assets/logo-transparente.png";
import PersonIcon from "@material-ui/icons/Person";
import MenuIcon from "@material-ui/icons/Menu";
import PropTypes from "prop-types";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
// import PopoverCarrinho from "./Popovers/PopoverCarrinho";
import DrawerMenu from "./DrawerMenu";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    background: `url("https://www.transparenttextures.com/patterns/fabric-plaid.png")`,
    backgroundColor: "#ffffff",
    minHeight: "165px",
    padding: 0,
  },
  buttonInicio: {
    color: "#005c2f",
    float: "right",
    marginLeft: 10,
    margin: theme.spacing(1),
  },
  container: {
    alignItems: "center",
    minHeight: 50,
    marginTop: 10,
    marginBottom: 10,
    padding: 0,
  },
  img: {
    cursor: "pointer",
    width: 170,
  },
  img2: {
    cursor: "pointer",
    width: 150,
    float: "left",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  menuButton: {
    float: "right",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

// const StyledBadge = withStyles((theme) => ({
//   badge: {
//     right: -3,
//     top: 13,
//     border: `2px solid ${theme.palette.background.paper}`,
//     padding: "0 4px",
//   },
// }))(Badge);

const options = ["Minha Conta", "Meus Pedidos", "Meus Dados"];

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export default function Header({ categorias, props }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [global] = useContext(GlobalContext);
  const { currentUser } = useContext(AuthContext);
  // const [, setAnchorElCarrinho] = useState(null);
  // const openCarrinho = Boolean(anchorElCarrinho);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleMenuItemClick = (index) => {
    setOpen(false);
    if (index === 3) {
      app
        .auth()
        .signOut()
        .then(function () {
          history.push("/");
          window.location.reload();
        })
        .catch(function (error) {
          console.log("Erro");
        });
    } else if (index === 2) {
      history.push("/minha-conta/perfil");
    } else if (index === 0) {
      history.push("/minha-conta");
    } else if (index === 1) {
      history.push("/minha-conta/pedidos");
    } else if (index === 4) {
      history.push("/carrinho");
    }
    setMobileOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  // const handlePopoverOpen = (event) => {
  //   setAnchorElCarrinho(event.currentTarget);
  // };

  // const handlePopoverClose = () => {
  //   setAnchorElCarrinho(null);
  // };

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  return (
    <header>
      <ElevationScroll {...props}>
        <AppBar style={{ backgroundColor: "#fafafa", borderBottom: "1px solid #dbdbdb" }}>
          <Toolbar className={classes.toolbar}>
            <Container component="main" maxWidth="lg">
              <Grid container spacing={0} className={classes.container}>
                <Hidden mdUp={true}>
                  <Grid item xs={10}>
                    <img
                      title={"Ir para início"}
                      src={logoVivaMinas}
                      className={classes.img2}
                      alt=""
                      onClick={() => history.push("/")}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      aria-label="Open drawer"
                      edge="end"
                      onClick={handleDrawerToggle}
                      className={classes.menuButton}
                    >
                      <MenuIcon style={{ color: "#005c2f" }} />
                    </IconButton>
                  </Grid>
                </Hidden>
                <Hidden smDown={true}>
                  <Grid item xs={4}>
                    <img
                      title={"Ir para início"}
                      src={logoVivaMinas}
                      className={classes.img}
                      alt=""
                      onClick={() => history.push("/")}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    {!currentUser ? (
                      <div style={{ float: "right" }}>
                        <Button
                          className={classes.buttonInicio}
                          onClick={() => history.push("/login")}
                          startIcon={<PersonIcon />}
                        >
                          Entrar
                          </Button>
                      </div>
                    ) : (
                      <div style={{ float: "right" }}>
                        <Button
                          ref={anchorRef}
                          className={classes.buttonInicio}
                          color="default"
                          aria-controls={
                            open ? "split-button-menu" : undefined
                          }
                          aria-expanded={open ? "true" : undefined}
                          aria-label="select merge strategy"
                          endIcon={<ArrowDropDownIcon />}
                          startIcon={<PersonIcon fontSize="large" />}
                          onClick={handleToggle}
                        >
                          Olá, {global.user.firstname}
                        </Button>
                        <Popper
                          open={open}
                          anchorEl={anchorRef.current}
                          role={undefined}
                          style={{ zIndex: 9999 }}
                          transition
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin:
                                  placement === "bottom"
                                    ? "center top"
                                    : "center bottom",
                              }}
                            >
                              <Paper variant="outlined" square>
                                <List style={{ padding: 0 }}>
                                  <ListItem>
                                    <ListItemText
                                      primary={`${global.user.firstname} ${global.user.lastname}`}
                                      secondary={
                                        global.user ? global.user.email : ""
                                      }
                                    />
                                  </ListItem>
                                </List>
                                <Divider />
                                <ClickAwayListener onClickAway={handleClose}>
                                  <MenuList
                                    id="split-button-menu"
                                    style={{ padding: 0 }}
                                  >
                                    {options.map((option, index) => (
                                      <MenuItem
                                        key={index}
                                        onClick={() =>
                                          handleMenuItemClick(index)
                                        }
                                      >
                                        {option}
                                      </MenuItem>
                                    ))}
                                    <MenuItem
                                      style={{
                                        borderTop: "1px solid #dbdbdb",
                                      }}
                                      onClick={() => handleMenuItemClick(3)}
                                    >
                                      Sair
                                      </MenuItem>
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>

                        {/* CARRINHO */}
                        {/* <Button
                          className={classes.buttonInicio}
                          aria-owns={
                            openCarrinho ? "mouse-over-popover" : undefined
                          }
                          onMouseEnter={handlePopoverOpen}
                          onMouseLeave={handlePopoverClose}
                          aria-label="cart"
                          onClick={() => history.push("/carrinho")}
                          size={"medium"}
                          startIcon={
                            <StyledBadge
                              badgeContent={global.pedido.itens}
                              color="error"
                              style={
                                global.pedido.itens
                                  ? { marginRight: 10 }
                                  : null
                              }
                            >
                              <ShoppingCartIcon
                                fontSize="large"
                                style={{ fontSize: 24 }}
                              />
                            </StyledBadge>
                          }
                        >
                          Carrinho
                        </Button>
                        <PopoverCarrinho
                          openCarrinho={openCarrinho}
                          anchorElCarrinho={anchorElCarrinho}
                          handlePopoverClose={handlePopoverClose}
                          global={global}
                        /> */}
                      </div>
                    )}
                    <Button
                      onClick={() => history.push("/quem-somos")}
                      className={classes.buttonInicio}
                    >
                      Quem somos
                      </Button>
                    {props.location.pathname !== "/" ? (
                      <Button href={"/"} className={classes.buttonInicio}>
                        Início
                      </Button>
                    ) : null}
                  </Grid>
                </Hidden>
              </Grid>
            </Container>
          </Toolbar>

          {/* SUBHEADER */}
          {/* <SubHeader categorias={categorias} /> */}
        </AppBar>
      </ElevationScroll>
      <DrawerMenu
        props={props}
        currentUser={currentUser}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        setMobileOpen={setMobileOpen}
        handleMenuItemClick={handleMenuItemClick}
        global={global}
      />
    </header>
  );
}
